/* ==========================================================================
   Tailwind setup
   ========================================================================== */

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

/* ==========================================================================
   Utils
   ========================================================================== */

@keyframes glow {
  0% { opacity: 0 }
  50% { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes fadein-up {
  0% { opacity: 0; transform: translateY(50px); }
  100% { opacity: 1; transform: translateY(0px); }
}

@keyframes sway {
  0% { transform: translateY(0px); }
  50% { transform: translateY(150px); }
  100% { transform: translateY(0px); }
}

.fadein-up {
  opacity: 0;
  animation-name: fadein-up;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.delay-1s {
  animation-delay: 0.5s;
}

.delay-2s {
  animation-delay: 1s;
}

.delay-3s {
  animation-delay: 1.5s;
}

/* ==========================================================================
   Primitives
   ========================================================================== */

body {
  -webkit-font-smoothing: antialiased;
}

a {
  @apply text-blue underline;
}

.heading {
  @apply font-sans font-semibold text-32;
}

@screen md {
  .heading {
    @apply text-40;
  }
}

.display {
  @apply font-serif font-normal text-40;
}

@screen md {
  .display {
    @apply text-48;
  }
}

.body {
  @apply font-sans font-normal text-18 text-blackL40;
}

.label {
  @apply font-sans font-semibold text-12 uppercase;
}

.btn {
  @apply inline-block px-6 py-4 font-semibold no-underline;
  transition: transform 0.25s, box-shadow 0.25s;
}

.btn-black {
  @apply font-semibold text-white bg-black rounded shadow-lg;
}

.btn-black:hover {
  @apply font-semibold shadow-lg-hover;
  transform: translateY(-3px);
}

.card {
  @apply shadow-sm bg-white;
  transition: transform 0.25s, box-shadow 0.25s;
}

.card:hover {
  @apply shadow-sm-hover;
  transform: translateY(-3px);
}

/* ==========================================================================
   Components
   ========================================================================== */

.bg {
  overflow: visible;
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 30%;
  background: linear-gradient(90deg, rgba(214, 238, 255, 1), rgba(214, 238, 255, 0));
  opacity: 0.4;
  animation: sway 6s infinite;
}


@screen md {
  .bg {
    opacity: 0.85;
  }
}

.bg:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(150, 211, 255, 0.5), rgba(150, 211, 255, 0));
  opacity: 0;
  animation: glow 6s 0s infinite;
}

.bg:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.bg-circle {
  position: absolute;
  z-index: 2;
  width: 560px;
  height: 560px;
}

.bg-circle--red {
  top: -100px;
  left: -380px;
  background: radial-gradient(circle closest-side, rgba(255, 218, 214, 1), rgba(255, 218, 214, 0));
}

.bg-circle--red:after {
  content: "";
  position: absolute;
  width: 560px;
  height: 560px;
  top: 0px;
  left: 0px;
  background: radial-gradient(circle closest-side, rgba(255, 175, 163, 0.5), rgba(255, 175, 163, 0));
  opacity: 0;
  animation: glow 4s 5s infinite;
}

.bg-circle--yellow {
  top: 140px;
  left: -140px;
  background: radial-gradient(circle closest-side, rgba(255, 251, 214, 1), rgba(255, 251, 214, 0));
}

.bg-circle--yellow:after {
  content: "";
  position: absolute;
  width: 560px;
  height: 560px;
  top: 0px;
  left: 0px;
  background: radial-gradient(circle closest-side, rgba(255, 244, 143, 0.2), rgba(255, 244, 143, 0));
  opacity: 0;
  animation: glow 6s 3s infinite;
}
